import { collectionsQueryOptions } from "@/api/queries/collection-queries.ts";
import { Collections } from "@/components/collections/collections.tsx";
import { Switch } from "@/components/core/forms/switch.tsx";
import { NotFound } from "@/components/errors/not-found.tsx";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_public/user_/$username/collections")({
	component: Page,
	errorComponent: () => <NotFound />,
});

function Page() {
	const [showAllItems, setShowAllItems] = useState(true);
	const collectionQuery = useQuery(collectionsQueryOptions());
	const collections = collectionQuery.data;

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">
							Your Collection
						</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-3">
						<span className="text-sm text-white/50">Show what's missing</span>
						<Switch checked={showAllItems} onCheckedChange={setShowAllItems} />
					</div>
				</div>

				<Collections
					collections={collections?.items || []}
					showAllItems={showAllItems}
				/>
			</div>
		</div>
	);
}
