import { activityQueryOptions } from "@/api/queries/activity-queries.ts";
import {
	rewardQueryOptions,
	useRewardClaimMutation,
} from "@/api/queries/reward-queries";
import XpEngine from "@/components/animations/xpengine.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "@/components/core/display/avatar.tsx";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/core/feedbacks/popover.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { ExpContext } from "@/components/exp-gain/exp-gain-context.tsx";
import { IconComic, IconExp, IconUser } from "@/components/mvicons";
import { useAuth } from "@/hooks/use-auth.tsx";
import { queryClient } from "@/query-client.ts";
import type { User } from "@/types/user.ts";
import { formatNumber } from "@/utils/format-number.ts";
import { humanReadableDateTime } from "@/utils/human-readable-datetime.ts";
import { shortenAddress } from "@/utils/shorten-address.ts";
import { timeDiffInSeconds } from "@/utils/time-diff-in-seconds.ts";
import { IconArrowRight } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import AnimatedNumbers from "react-animated-numbers";

const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

export function Timer({
	duration,
	onClaim,
}: {
	duration: number;
	onClaim?: () => void;
}) {
	const [timeLeft, setTimeLeft] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [isTimeUp, setIsTimeUp] = useState(false);
	const [targetTime, setTargetTime] = useState(Date.now() + duration * 1000);

	useEffect(() => {
		const updateTimer = () => {
			const now = Date.now();
			const distance = targetTime - now;

			const hours = Math.floor(distance / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			if (duration <= 0) {
				clearInterval(timerInterval);
				setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
				setIsTimeUp(true);
			} else {
				setTimeLeft({ hours, minutes, seconds });
			}
		};

		const timerInterval = setInterval(updateTimer, 1000);

		return () => clearInterval(timerInterval);
	}, [targetTime]);

	return (
		<>
			{isTimeUp ? (
				<Button
					className="font-quagmire w-full h-[32px] max-w-[80px] bg-mvdark-950/60 uppercase"
					type="button"
					size="sm"
					variant="gradient"
					gradient="default"
				>
					<ButtonLabel>Claim</ButtonLabel>
				</Button>
			) : (
				<div className="font-aller inline-flex self-start flex-row items-center w-auto pt-1 pb-1.5 px-3 gap-1 rounded-2xl text-white bg-white bg-opacity-5">
					<span className="font-bold text-white leading-tight">
						{String(timeLeft.hours).padStart(2, "0")}
					</span>
					<span className="font-bold text-white/50 leading-tight">:</span>
					<span className="font-bold text-white leading-tight">
						{String(timeLeft.minutes).padStart(2, "0")}
					</span>
					<span className="font-bold text-white/50 leading-tight">:</span>
					<span className="font-bold text-white leading-tight">
						{String(timeLeft.seconds).padStart(2, "0")}
					</span>
				</div>
			)}
		</>
	);
}

export default function PortalTopbar({ profile }: { profile: User }) {
	const [open, setOpen] = useState(false);
	const { logout } = useAuth();
	const navigate = useNavigate({
		from: "/",
	});
	const modal = useModal();
	const { triggerExpAnim, triggerAnimation } = useContext(ExpContext);
	const rewardMutation = useRewardClaimMutation();
	const rewardQuery = useQuery(rewardQueryOptions());
	const rewardStatus = rewardQuery.data;
	const activityQuery = useQuery(activityQueryOptions());
	const activities = activityQuery.data;

	return (
		<div
			className={clsx(
				"!z-20 fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-black/90 to-transparent",
				isProduction ? "top-0" : "top-8",
			)}
		>
			<Link to="/overview" className="inline-flex h-[44px]">
				<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
			</Link>
			<div className="inline-flex flex-row items-center w-auto gap-4">
				<div className="inline-flex flex-row items-center w-auto gap-1 text-white">
					<IconExp size={24} />
					{triggerExpAnim ? (
						<AnimatedNumbers
							className="text-sm text-white"
							includeComma
							transitions={(index) => ({
								type: "spring",
								duration: index + 0.5,
							})}
							animateToNumber={profile.totalExp || 0}
						/>
					) : (
						<span className="text-sm">
							{formatNumber(profile.totalExp, "0,0")}
						</span>
					)}
				</div>
				{profile?.aptosAddress && isProduction && (
					<div className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/40 font-bold text-sm text-white">
						{shortenAddress(profile?.aptosAddress)}
					</div>
				)}
				<Popover open={open} onOpenChange={setOpen}>
					<PopoverTrigger
						className="!outline-none"
						onMouseEnter={() => setOpen(true)}
						onMouseLeave={() => setOpen(true)}
					>
						<Avatar className="w-[48px] h-[48px] rounded-full border-2 border-white bg-mvclaimcard-gradient-a pointer-events-auto">
							<AvatarImage src={profile.profilePictureUrl} />
							{!profile.profilePictureUrl && (
								<AvatarImage src="/macronaut-avatar.png" />
							)}
						</Avatar>
					</PopoverTrigger>
					<PopoverContent
						onMouseEnter={() => setOpen(true)}
						onMouseLeave={() => setOpen(false)}
						side="bottom"
						align="center"
						sideOffset={-30}
						allowClose={false}
						enableArrow={false}
					>
						<div className="flex flex-col w-full max-w-[320px] sm:max-w-[420px] gap-2">
							<div className="flex flex-row items-center justify-center w-full gap-2">
								<Avatar className="w-[48px] h-[48px] rounded-full border-2 border-white bg-mvclaimcard-gradient-a pointer-events-auto">
									<AvatarImage src={profile.profilePictureUrl} />
									{!profile.profilePictureUrl && (
										<AvatarImage src="/macronaut-avatar.png" />
									)}
								</Avatar>
								<Button
									onClick={() => {
										modal.open({
											type: "confirm",
											variant: "warning",
											title: "Are you sure you want to logout?",
											description:
												"It looks like you're about to log out. We'll miss you! If you're all set, you can safely end your session now.",
											onConfirm: async () => {
												await navigate({
													to: "/",
												});

												await queryClient.invalidateQueries();
												await logout();
											},
										});
									}}
									className="!outline-none self-center font-quagmire backdrop-blur uppercase pointer-events-auto"
									type="button"
									variant="gradient"
									size="md"
								>
									<ButtonLabel>Logout</ButtonLabel>
								</Button>
							</div>

							{!rewardQuery.isFetching && rewardStatus && (
								<div className="flex flex-row w-full p-2 gap-2 rounded-xl bg-white bg-opacity-5">
									<div className="inline-flex">
										<XpEngine className="w-[80px] h-[80px]" />
										{/*<img src="/svg/exp-engine.svg" alt="Macroverse" />*/}
									</div>
									<div className="flex flex-col justify-center w-auto gap-1">
										<span className="text-white text-sm">
											Claim your next XP in
										</span>
										<Timer
											onClaim={async () => {
												triggerAnimation();

												await rewardMutation.mutateAsync();
											}}
											duration={
												timeDiffInSeconds(rewardStatus?.nextClaimAt) || 0
											}
										/>
									</div>
								</div>
							)}

							<div className="flex flex-col w-full py-2 px-4 rounded-xl divide-y divide-white/20 bg-white bg-opacity-5">
								<Link
									to="/user/$username/about"
									params={{
										username: (profile.username ||
											profile.macronautId) as string,
									}}
								>
									<div className="flex flex-row items-center gap-2 py-1 text-white/70 hover:text-white">
										<IconUser size={16} />
										<span className="translate-y-[-1px] leading-1">
											Profile
										</span>
										<IconArrowRight className="ml-auto" size={18} stroke={2} />
									</div>
								</Link>
								{/* This will be added later on don't remove this comment */}
								{/*<Link to="/profile">
									<div className="flex flex-row items-center gap-2 py-1 text-white/70 hover:text-white">
										<IconExp size={18} />
										<span className="translate-y-[-1px] leading-1">
											Your Quests
										</span>
									</div>
								</Link>*/}
							</div>

							<div className="flex flex-col w-full mt-2 gap-2">
								<div className="flex flex-row justify-center w-full">
									<span className="font-semibold text-white">
										Latest Updates
									</span>
								</div>
								<div className="flex flex-col w-full rounded-xl divide-y divide-white/10 py-3 px-6 bg-white bg-opacity-5">
									{activities?.items.map((activity) => (
										<div
											key={activity.id}
											className="flex flex-row items-center w-full py-3 gap-3"
										>
											<Avatar className="self-start shrink-0 w-[36px] h-[36px] rounded-full border-2 border-white bg-mvlogo-gradient">
												<AvatarImage src="/macronaut-avatar.png" />
												<AvatarFallback>AA</AvatarFallback>
											</Avatar>
											<div className="flex flex-col items-start w-full shrink grow">
												<p className="text-xs text-mvblue-300 leading-tight">
													You received{" "}
													<span className="inline mx-1 text-white">
														<IconExp
															className="translate-y-[2px] mr-1"
															size={14}
														/>
														+{activity.properties?.amount} XP
													</span>{" "}
													Reward
												</p>
												<span className="text-xs text-white/30  leading-tight">
													{humanReadableDateTime(activity.createdAt)}
												</span>
											</div>
										</div>
									))}
								</div>
							</div>

							{/*<div className="flex flex-col w-full mt-2 gap-2">
								<div className="flex flex-row justify-center w-full">
									<div className="inline-flex flex-row items-center justify-center w-auto gap-2 h-[32px] rounded-full px-4 border border-white/20 text-white">
										<IconNotifications size={16} />
										<span className="text-sm">125 Notifications</span>
									</div>
								</div>
								<div className="flex flex-col w-full p-4 gap-3 rounded-xl bg-white bg-opacity-5">
									<div className="relative flex flex-row justify-center w-full">
										<div className="inline-flex flex-row w-auto gap-2">
											<div className="inline-flex items-center justify-center w-[24px] h-[24px] rounded-full bg-white/20">
												<span className="font-semibold text-xs text-white">
													99
												</span>
											</div>
											<span className="font-semibold text-white">
												New Authentic Editions
											</span>
										</div>
										<span className="absolute top-0 right-0 ml-auto text-xs text-white/30">
											Now
										</span>
									</div>
									<div className="flex flex-row w-full gap-3">
										<div className="relative inline-flex h-[50px] mt-1 shrink-0">
											<div className="absolute -bottom-[8px] right-1 inline-flex justify-center items-center w-[24px] h-[24px] rounded-md bg-mvdark-950 border border-white/20 text-white">
												<IconComic size={14} />
											</div>
											<img
												className="block h-full w-auto"
												src="/sample-comic-bundle.png"
												alt="Macroverse"
											/>
										</div>
										<div className="flex flex-col w-full">
											<span className="font-semibold text-white leading-tight">
												Thrilling BONUS Issues!
											</span>
											<span className="text-white/70 leading-tight">
												Classic Pre-Code Horror exclusive additions are now on
												Macroverse
											</span>
										</div>
									</div>
									<div className="flex flex-row justify-center w-full">
										<Timer />
									</div>
								</div>
							</div>*/}

							<div className="flex flex-col w-full mt-2 gap-2">
								<div className="flex flex-row justify-center w-full">
									<span className="font-semibold text-white">New For You</span>
								</div>
								<div className="relative flex flex-col w-full p-4 gap-3 rounded-xl bg-white bg-opacity-5">
									<span className="absolute top-2 right-3 ml-auto text-xs text-white/30">
										18m ago
									</span>
									<div className="flex flex-row items-center w-full gap-3">
										<div className="relative inline-flex h-[50px] mt-1 shrink-0">
											<div className="absolute -bottom-[8px] -right-[4px] inline-flex justify-center items-center w-[24px] h-[24px] rounded-md bg-mvdark-950 border border-white/20 text-white">
												<IconComic size={14} />
											</div>
											<img
												className="block h-full w-auto"
												src="/sample-comic-solo.png"
												alt="Macroverse"
											/>
										</div>
										<div className="flex flex-col w-full">
											<span className="font-semibold text-white leading-tight">
												New Issue
											</span>
											<span className="text-white/70 leading-tight">
												Chamber of chills #24 is now available...
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</PopoverContent>
				</Popover>
			</div>
		</div>
	);
}
